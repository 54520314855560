.box-slider-setting {
  width: 64px;
  height: 31px;
  border-radius: 13px;
  background: #ededed;
  position: relative;
  cursor: pointer;
}
.box-indicator-setting {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #134e96;
  position: absolute;
  top: 7px;
  right: 5px;
  transition: left 0.2s ease-in-out, background 0.2s ease-in-out;
}
.box-row {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}
