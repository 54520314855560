.box-video {
  /* width: 100%; */
  /* height: 415.22px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  position: relative;
  margin: 1rem 1rem;
}
.box-video:hover {
  /* box-shadow: 0px 0px 14px #134e96; */
  box-shadow: 0px 0px 35px rgba(19, 78, 150, 0.18);
  /* box-shadow: #7aa4dd 0px 8px 24px; */
}
.box-video img {
  width: 100%;
  /* height: 100%; */
  max-height: 800px;

  object-fit: cover;
  border-radius: 35px;
  position: relative;
}
.box-title-video {
  width: 50%;
  height: 45.57px;
  border-radius: 35px 0px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.box-zoom-video {
  position: absolute;
  right: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  padding: 10px;
  border-radius: 0px 35px;
}
.box-zoom-video:hover {
  box-shadow: 0px 0px 35px rgba(19, 78, 150, 0.18);
}
