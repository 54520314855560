.drop-down-fade {
  /* width: 232px;
  height: 41px; */
  border-radius: 11px;
  background: #ebf4ff;
  box-shadow: 0px 0px 6px rgba(19, 78, 150, 0.09);
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #7b7b7b;
  border: none;
  padding: 0.5rem 2rem;
  column-gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.box-dropdown {
  /* width: 303px;
  height: 177px; */
  border-radius: 11px;
  background: #ebf4ff;
  box-shadow: 0px 0px 6px rgba(19, 78, 150, 0.09);
  margin-top: 1rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  z-index: 1000;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}

.box-dropdown ul li {
  list-style-type: none;
  padding-left: 1rem;
  padding-right: 2rem;
}
.box-dropdown ul {
  padding: 0 0.5rem;
}
.box-dropdown ul li:hover {
  text-decoration: underline;
  color: #7b7b7b;
  filter: brightness(76%);
}
.box-relative {
  position: relative;
}
#show-dropdown {
  opacity: 1;
  height: auto;
}
#hide-dropdown {
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
