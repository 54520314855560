.box-dropdown-filter {
  border-radius: 19px;
  background: #ebf4ff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: left;
  color: #7b7b7b;
  border: none;
  cursor: pointer;
  padding: 0.8rem 1rem;
  white-space: nowrap;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.box-dropdown-filter-cover
  > .box-dropdown-filter:focus
  + .box-dropdown-filter-selector {
  /* background-color: coral; */
  opacity: 1;
  height: auto;
  visibility: visible;
}
.box-dropdown-filter-selector {
  display: flex;
  width: 100%;
  visibility: hidden;
  flex-direction: column;
  /* height: 0; */
  border-radius: 11px;
  background: #ebf4ff;
  box-shadow: 0px 0px 6px rgba(19, 78, 150, 0.09);
  position: absolute;
  /* top: 3.6rem; */
  opacity: 0;
  z-index: 2000;
  transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  cursor: pointer;
}
.box-dropdown-filter-cover {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.box-dropdown-filter-selector ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  padding-right: 2rem;
}
.box-dropdown-filter-selector ul {
  padding: 0 0;
  margin: 0;
  width: auto;
}
.box-dropdown-filter-selector ul li:hover {
  color: #7b7b7b;
  background-color: #cdd7e349;
  border-radius: 0.2rem;
  filter: brightness(76%);
}
.txt-drop-down {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: left;
  color: #7b7b7b;
  cursor: pointer;
  margin-bottom: 0.2rem;
}
