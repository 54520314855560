.px-6per {
  padding: 0 6%;
}
.row-sb-m-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem;
}
.row-jcen {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.row-jcen-pt2 {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-top: 2rem;
}
.row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  column-gap: 1rem;
}
.col-st {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.col-st-gab1 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  row-gap: 1rem;
}
.row-jcst-atfs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  column-gap: 1rem;
}
.row-sb-pt2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  column-gap: 1rem;
  margin-top: 2rem;
}

.row-jcct {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.row-jcsb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row-jcst {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.row-jcsb-px2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  padding-right: 3rem;
}
.row-jcsb-px2-gab2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  column-gap: 2rem;
}
.mx {
  margin: 0 1rem;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.col-jcct-scrol-70perh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
  overflow-y: scroll;
  overflow-x: visible;
}
.pt2 {
  padding-top: 2rem;
  /* padding-bottom: 1rem; */
}
.table-jcct {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
}
.grid2x2 {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 2rem;
}
.row-jcct-px2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
}
.col-jcst-atct {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  row-gap: 0.7rem;
}
.row-jcct-my2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1.5rem 0;
}
.row-jcct-my1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
}
.col-jcst-atst {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  /* width: 25%; */
}
.col-jcst-atct-gab1 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  row-gap: 1rem;
}
.row-jcsb-w100 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.col-jcct-atct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col-gab1 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.col-gab1-w50 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 50%;
}
.row-gab2 {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
}
.row-gab2-w100 {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 2rem;
  align-items: flex-end;
  /* column-gap: 2rem; */
}
.row-gab1 {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}
.row-jcct-py1-5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1.5rem 0;
}
.row-jcct-py2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 0;
}
.row-jcct-py2-gab2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 0;
  column-gap: 2rem;
}
.row-jcct-pt2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 0;
  padding-top: 1rem;
}
.row-jcct-pt2-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 0;
  padding-top: 2rem;
}
.row-jcst-atct {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  column-gap: 1rem;
}
.row-jcct-gab2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 2rem;
}
.row-jcct-py {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 0;
}
.col-aict {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.col-aict-gab2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}
.row-jcfe-my2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0;
  column-gap: 1rem;
}
.col-jcfs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.col-jcfs-w100 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.col-jcfs-w100-gab1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 1rem;
}
.row-jcsb-atct {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 2rem;
  align-items: center;
}
.col-mt-w100 {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
}
.col {
  display: flex;
  flex-direction: column;
}
.row-gab2-w100 {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
}
.row-gab2-mt2 {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  margin-top: 2rem;
}
.row-gab2-my2 {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.cursor-pointer {
  cursor: pointer;
}
.pd10 {
  padding: 10px;
}
.pd15 {
  padding: 15px;
}
.ml-2 {
  margin-left: 2rem;
}

.mb-0px {
  margin-bottom: 0px;
}