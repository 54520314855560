.box-fill {
  border-radius: 35px;
  background: #fff;
  padding: 1.5rem 2rem;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.h500px {
  height: 500px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* label */
