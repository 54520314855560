.div-border-button-train-image-user {
    position: relative;
    height: 30px;
    width: 60px;
    top: -22px;
    left: -20px;
    padding-left: 5px;
    border-radius: 25px 0px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background: #FFF;
}

.border-cursor-pointer{
    cursor: pointer;
}

.text-p-no-user {
    position: absolute;
    left: 15px;
    top: 3px;
    font-family: Kanit;
    font-weight: normal;
    font-size: 1rem;
}

  .div-button-train-image {
      padding : 10px;
      border-radius: 25px;
      width: 120px;
      margin-top: -20px;
      background: #FFF;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .div-button-train-image:hover {
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

  .div-border-icon-train-image {
    border-radius: 50%;
    background-color : #fc8181;
    width: 30px;
    display: block;
    margin: auto;
}

.class-icon-train-image {
text-align: center;
color: #FFF;
}

.front-icon-train-image {
margin: 10px auto 0px;
font-family: Kanit;
font-weight: normal;
font-size: 1rem;
}