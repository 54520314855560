.text-announcement-display {
  font-family: Kanit;
    font-size: 20px;
    text-align: left;
    color: #707070;
}

.btn-add-and-remove-announcement-component{
  width: 40px;
  height: 40px;
  border-radius: 7px;
  margin: 15px 0px 0px 10px;
  border: none;
}

.btn-remove-announcement-component {
  background-color: #ea6969;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btn-remove-announcement-component:hover {
  background-color: #FF4459;
  box-shadow: #FF4459 0px 3px 8px;
}

.btn-add-new-announcement-component {
  background-color: #0BC236;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btn-add-new-announcement-component:hover {
  background-color: #69ea87;
  box-shadow: #69ea87 0px 3px 8px;
}