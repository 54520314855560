.semi-circle {
  width: 50px;
  height: 50px;
  border-color: cadetblue;
  border-radius: 50%;
  position: absolute;
  z-index: 1600;
}
.semi-circle-by-nile {
  width: 50px;
  height: 50px;
  border-color: cadetblue;
  border-radius: 50%;
  position: absolute;
  /* z-index: 1600; */
}
.box-cover-circle {
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 1600;
}
.box-cover-circle-by-nile {
  width: 50px;
  height: 50px;
  position: relative;
  /* z-index: 1600; */
}
.full-background-circle {
  width: 50px;
  height: 50px;
  background: transparent;
  border: 14px solid #f0f0f0;
  border-radius: 50%;
  position: absolute;
}
.box-circle {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
}
.box-hover-top {
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  position: absolute;
  bottom: 55px;
  right: -55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 1500;
  transition: height 1s ease-in-out;
}
#show-circle {
  height: auto;
  opacity: 1;
  overflow: hidden;
}
#hide-circle {
  height: 0;
  opacity: 0;
  padding: 0;
}
#hide-circle p {
  font-size: 0%;
}
