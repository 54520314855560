.row-jcse-login {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100vh;
    align-items: center;
  }
  .col-jcct-aict-login {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
    width: 45%;
    /* background-color: aquamarine; */
  }
  .space-login-4rem {
    padding-top: 4rem;
  }
  .img-logo-login {
    height: 22vh;
    width: 100%;
    object-fit: contain;
    justify-content: center;
  }
  @media only screen and (max-width: 600px) {
    .row-jcse-login {
      flex-direction: column-reverse;
    }
    .space-login-4rem {
      padding-top: 1rem;
    }
    .col-jcct-aict-login {
      width: 90%;
      row-gap: 1rem;
    }
    .img-logo-login {
      height: 9vh;
    }
  }

  .txt-header {
    font-family: Kanit;
    font-weight: normal;
    font-size: 35px;
    text-align: center;
    color: #7b7b7b;
    margin: 0;
}

.txt-title-field {
    font-family: Kanit;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #707070;
    margin: 0;
}

.txt-logo {
    font-family: Kanit;
    font-weight: normal;
    font-size: 2rem;
    text-align: left;
    color: #134e96;
    margin: 0;
}
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 768px) {
    .row-jcse-login {
      flex-direction: column-reverse;
    }
    .space-login-4rem {
      padding-top: 1rem;
    }
    .col-jcct-aict-login {
      width: 90%;
      row-gap: 1rem;
    }
    .img-logo-login {
      height: 12vh;
    }
  }
  