.box-switch-add-new-student {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 1.2rem 1.2rem;
  cursor: pointer;
  position: relative;
  transition: outline 0.125s ease-in-out;
}
.img-box-switch {
  width: 2rem;
}
.box-checked {
  /* width: 46px;
  height: 44px; */
  border-radius: 0px 19px;
  background: #134e96;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 0.6rem;
  opacity: 0;
  transition: opacity 0.125s ease-in-out;
}
#selected-box {
  outline: 4px solid #134e96;
  box-shadow: 0px 0px 26px rgba(19, 78, 150, 0.28);

}
#selected-checked {
  opacity: 1;
}

.box-switch-add-new-student-by-nile {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 1.2rem 1.2rem;
  cursor: pointer;
  position: relative;
  transition: outline 0.125s ease-in-out;
  /* width: 150px; */
  margin: 10px;
}

/* .box-switch-add-new-student {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 1.2rem 1.2rem;
  cursor: pointer;
  position: relative;
  transition: outline 0.125s ease-in-out;
}
.img-box-switch {
  width: 2rem;
} */
/* .box-checked { */
  /* width: 46px;
  height: 44px; */
  /* border-radius: 0px 19px;
  background: #134e96;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 0.6rem;
  opacity: 0;
  transition: opacity 0.125s ease-in-out;
}
#selected-box {
  outline: 4px solid #134e96;
  box-shadow: 0px 0px 26px rgba(19, 78, 150, 0.28);

}
#selected-checked {
  opacity: 1;
} */
