.box-add-device-title {
  width: 60%;
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 2rem;
  margin-top: 2rem;
  /* align-items: center; */
}
.box-add-device-title-by-nile {
  /* width: 60%; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 2rem;
  margin-top: 2rem;
  /* align-items: center; */
}
.box-each-fill {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  margin-top: 2rem;
}
.box-each-fill-by-nile {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  margin-top: 2rem;
}
.box-add-map {
  width: 100%;
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 2rem;
  /* height: 300px; */
}
.box-add-map img{
    width: 100%;
    margin-top: 1rem;
    border-radius: 35px;
}
.box-btn-save{
    width: 60%;
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
