.DatePicker-style-custom {
  text-align: center !important;
  font-family: Kanit !important;
  font-weight: normal !important;
  font-size: 1.5rem !important;
  height: 50px !important;
  width: 100% !important;
  border-radius: 25px !important;
  margin: 0px !important;
  padding: 15px !important;
  background: #ebf4ff !important;
  border : none !important;
}

.text-header-date-picker {
  font-family: Kanit !important;
  font-weight: normal !important;
  font-size: 1.2rem !important;
  color: #707070;
}

.div-DatePicker-style-custom {
  margin-bottom: 20px;
}

.ant-picker-clear {
  background: #ebf4ff !important;
}