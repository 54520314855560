.box-new-device {
  width: 61%;
  /* height: 394px; */
  border-radius: 35px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
}
.box-new-device img{
    height: fit-content;
    object-fit: contain;
}

.box-new-device-by-nile {
  width: 61%;
  /* height: 394px; */
  border-radius: 35px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  display: block;
  margin: auto;
}

.box-new-device-by-nile img{
    height: fit-content;
    object-fit: contain;
}

.button-select-report-record-page{
  margin: 0px;
}

@media only screen and (max-width: 1199px) {
  .div-border-br-checkbox-modal {
    margin-top: 20px;
  }

}

@media only screen and (max-width: 767px) {
  .box-new-device-by-nile {
    width: 100%;
  }

  .box-new-device-by-nile img{
    width: 100%;
}

.button-select-report-record-page{
  margin-top: 20px;
}

}

@media only screen and (min-width: 767px) {
  .box-new-device-by-nile {
    width: 61%;
  }
}