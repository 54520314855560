.input-style-by-nile {
    font-family: Kanit !important;
    font-size: 1rem !important;
    height: 50px !important;
    border-radius: 25px !important;
    margin: 0px !important;
    padding: 15px !important;
    background: #ebf4ff !important;
    border : none !important;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    background: #ebf4ff !important;
}