.qr-code-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
}

.qr-code-box-by-nile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
}
