.table-log-all-components {
  font-family: Kanit;
  font-weight: normal;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.table-log-all-components td,
.table-log-all-components th {
  border: none;
  color: #7b7b7b;
  padding: 20px;
}

.tr-table-components:hover {
  /* box-shadow: 0px 0px 2px rgba(19, 78, 150, 0.18); */
  box-shadow: rgba(0, 0, 0, 0.075) 0px 5px 15px 0px;
  outline: 2px solid rgba(19, 78, 150, 0.03);
}

table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1; 
  background: #fff; 
}

.tr-table-components {
  /* height: 72px; */
  /* box-shadow: 0px 0px 14px rgb(19 78 150 / 9%); */
  border-radius: 26px;
  text-align: center;

  /* background: #ffeded; */
}

.table-log-all-components th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  color: #134e96;
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
}

/* th,
td {
  min-width: 120px;
} */

.td-first-table {
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
}

.td-last-table {
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
}

.text-columns-table-by-nile {
  margin-bottom: -20px;
}

.div-table-components-by-nile {
  height: 100%;
  /* height: 400px; */
  width: 100%;
  overflow-x: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.div-line-right-by-nile {
  width: 2px;
  height: 24px;
  float: right;
  background: #f0f0f0;
}
