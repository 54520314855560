.header-graph {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    padding-top: 1rem;
  }
  .header-device {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 1rem;
    padding-top: 1rem;
  }
  .box-device{
    width: 536px;
    height: 277px;
    border-radius: 35px;
    background: #fff;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
    display: flex;
    flex-direction: column;
    justify-content: start;
    white-space: nowrap;
    margin: 0 1rem;
    padding: 1rem 1rem;
  }
  .box-device-by-nile {
    width: 536px;
    /* height: 277px; */
    border-radius: 35px;
    background: #fff;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
    display: flex;
    flex-direction: column;
    justify-content: start;
    white-space: nowrap;
    margin: 10px;
    padding: 1rem 1rem;
  }
  .box-graph-donut {
    width: 15%;
    height: 15%;
  }
  .each-box-device {
    width: 100%;
    height: 65%;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .box-table {
    width: 50%;
    height: 400px;
    border-radius: 14px;
    background: #fff;
    box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
    margin-bottom: 2rem;
  }

  .box-table-by-nile {
    height: 600px;
    /* height: 500px; */
    border-radius: 14px;
    background: #fff;
    box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
    margin-bottom: 2rem;
  }

  .box-realtime {
    width: 96%;
    height: 72px;
    border-radius: 25px;
    background: #fff;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
    /* margin-right: 2rem; */
    padding: 0.5rem 0.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .divider {
    width: 2px;
    height: 24px;
    background: #f0f0f0;
  }
  .img35 {
    height: 35%;
    object-fit: cover;
  }

  .header-box {
    /* width: 234px;
    height: 107px; */
    border-radius: 24px;
    padding: 0.5rem 1rem;
    background: #fff;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
  }
  .img-header-box {
    width: 35px;
    height: 35px;
  }
  .box-graph {
    width: 100%;
    height: 452px;
    border-radius: 35px;
    background: #fff;
    box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
    margin-top: 1rem;
  }

  .box-device-information-by-nile {
    width: 100%;
    /* height: 452px; */
    border-radius: 35px;
    background: #fff;
    box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
    margin-top: 1rem;
  }

  .new-header-box-by-nile {
    /* width: 234px;
    height: 107px; */
    border-radius: 24px;
    padding: 0.5rem 1rem;
    background: #fff;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 20px;
  }

  .img-header-box-by-nile {
    width: 35px;
    height: 35px;
    display: block;
    margin : 16px auto 0 auto
  }

  .img-table-by-nile {
    height: 35%;
    object-fit: cover;
  }