.text-field {
  width: 100%;
  border-radius: 16px;
  padding: 0.5rem 2rem;
  background: #ebf4ff;
  border: none;
  box-shadow: 0px 0px 6px rgba(19, 78, 150, 0.09);
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #7b7b7b;
}
.text-field:focus {
  outline: 2px solid #d8d8d8;
}
.text-field::placeholder {
  font-weight: lighter;
  font-size: 0.9rem;
  /* text-align: center; */
}
::placeholder {
  color: #bebdbd;
  opacity: 1; 
}

:-ms-input-placeholder { 
 color: #bebdbd;
}

::-ms-input-placeholder { 
 color: #bebdbd;
}