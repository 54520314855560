.box-profile-student {
  width: auto;
  /* height: 538px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  padding: 3vw 3vw;
  padding-top: 0;
}
.box-inside-profile-student {
  width: auto;
  /* height: 358px; */
  border-radius: 31px;
  background: transparent;
  border: 1px dashed #d8d8d8;
  padding: 1rem 1rem;
  position: relative;
}
.box-image-profile {
  width: 141px;
  height: 141px;
  border-radius: 29px;
  background-color: coral;
  object-fit: cover;
  filter: drop-shadow(0px 0px 14px rgba(19, 78, 150, 0.09));
}
.box-top-left-button {
  position: absolute;
  top: 0;
  right: 0;
}
.box-profile-student-by-nile {
  /* width: auto; */
  /* height: 538px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  padding: 20px;
  /* padding: 3vw 3vw;
  padding-top: 0; */
}
.box-inside-profile-student-by-nile {
  /* width: auto; */
  /* height: 358px; */
  border-radius: 31px;
  background: transparent;
  border: 1px dashed #d8d8d8;
  /* padding: 1rem 1rem; */
  padding: 20px;
  position: relative;
}
.text-header-personal{
  text-align: center;
  color: #134e96;
  font-family: Kanit;
  font-weight: bold;
  font-size: 1.1rem;
}
.text-personal-detail {
  color: #7b7b7b;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
}

.tick-personal-detail{
  display: none;
}

.box-image-profile-lg-by-nile {
  width: 141px;
  height: 141px;
  border-radius: 29px;
  display: block;
  margin: auto;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  /* background-color: coral;
  object-fit: cover;
  filter: drop-shadow(0px 0px 14px rgba(19, 78, 150, 0.09)); */
}

.box-image-profile-sm-by-nile {
  display: none;
}

@media only screen and (max-width: 991px) {
  .box-image-profile-lg-by-nile {
    display: none;
  }

  .box-image-profile-sm-by-nile {
    width: 141px;
    height: 141px;
    border-radius: 29px;
    display: block;
    margin: 0px auto 20px;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  }
}

@media only screen and (max-width: 575px) {

  .box-image-profile-lg-by-nile {
    display: none;
  }

  .tick-personal-detail{
    display: inline;
    margin-right: 10px;
  }

}

/* @media only screen and (min-width: 767px) {
  .box-new-device-by-nile {
    width: 61%;
  }
} */