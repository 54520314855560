.txt-top-title {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: left;
  color: #134e96;
  text-shadow: 3px 3px 9px rgba(0, 0, 0, 0.16);
  width: 45%;
  display: inline;
}

.txt-top-title-by-nile {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: center;
  color: #134e96;
  text-shadow: 3px 3px 9px rgba(0, 0, 0, 0.16);
}

.txt-sub-header1 {
  font-family: Kanit;
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
  color: #707070;
  margin: 0 0;
}
.txt-sub-header1-by-nile {
  font-family: Kanit;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
  color: #707070;
  /* margin: 0 0; */
  padding-top: 20px;
  /* padding-bottom: 10px; */
}
.txt-higlight1 {
  font-family: Kanit;
  font-weight: normal;
  font-size: 2rem;
  text-align: left;
  color: #a7a7a7;
  margin: 0 0;
  white-space: nowrap;
}
.txt-main-content1 {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #403d3d;
  margin: 0;
}
.txt-sub-explanation1 {
  font-family: Kanit;
  font-weight: normal;
  font-size: 0.8rem;
  text-align: left;
  color: #7b7b7b;
  margin: 0;
}
.txt-main-content3 {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #a7a7a7;
}
.txt-main-content4 {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: left;
  color: #7b7b7b;
  padding: 0;
  margin: 0;
}
.txt-main-content2 {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #7b7b7b;
}
.txt-sub-explanation1 {
  font-family: Kanit;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #7b7b7b;
}
.txt-unfocus1 {
  font-family: Kanit;
  font-weight: normal;
  font-size: 9px;
  text-align: left;
  color: #7b7b7b;
}

.txt-sub-explanation1-by-nile {
  font-family: Kanit;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #7b7b7b;
  margin-top: 10px;
}
.txt-unfocus1-by-nile {
  font-family: Kanit;
  font-weight: normal;
  font-size: 11px;
  text-align: center;
  color: #7b7b7b;
}
.txt-sub-header1-light {
  font-family: Kanit;
  font-weight: lighter;
  font-size: 1rem;
  text-align: left;
  color: #707070;
  margin: 0 0;
}
.txt-sub-header1-font-weight-400 {
  font-family: Kanit;
  font-weight: 400;
  font-size: 1rem;
  text-align: left;
  color: #707070;
  margin: 0 0;
}
.text-header-center-by-nile {
  font-family: Kanit;
  font-weight: 400;
  font-size: 1.3rem;
  text-align: center;
  font-size: 2rem;
  color: #5a5959;
  margin: 10px;
}
.txt-top-show-header {
  font-family: Kanit;
  font-weight: normal;
  font-size: 45px;
  text-align: left;
  color: #cbcbcb;
  margin: 0;
  padding: 0;
}
.txt-top-show-header-by-nile {
  font-family: Kanit;
  font-weight: normal;
  font-size: 25px;
  /* text-align: left; */
  color: #cbcbcb;
  margin: 0;
}
.txt-top-show-content {
  font-family: Kanit;
  font-weight: normal;
  font-size: 24px;
  text-align: left;
  color: #7b7b7b;
  margin: 0;
  padding: 0;
}
.txt-top-show-content-by-nile {
  font-family: Kanit;
  font-weight: normal;
  font-size: 20px;
  text-align: left;
  color: #7b7b7b;
  margin: 0;
}
.mt-20px {
  margin-top: 20px;
}

.mb-20px {
  margin-bottom: 20px;
}
.mb-10px {
  margin-bottom: 10px;
}

.text-color-success-by-nile {
  color: #69ea87;
}

.text-color-reboot-device-by-nile {
  color: #FCB64A;
}

.text-color-offline-device-by-nile {
  color: #707070;
}

.text-color-error-by-nile {
  color: #ea6969;
}

.text-in-card-detail-user-log-by-nile {
  font-family: Kanit;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: #7b7b7b;
}

.txt-header-Dashboard {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #7b7b7b7c;
}

.txt-header-time-Dashboard {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #7b7b7b;
}
.txt-under-title {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #7b7b7b;
  margin: 0 0;
  padding: 0 0;
}
.txt-top-title-school-by-nile {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.5rem;
  color: #134e96;
  text-shadow: 3px 3px 9px rgba(0, 0, 0, 0.16);
  text-align: left;
  margin: 0 0;
  padding: 0 0;
}
.txt-header-setting {
  font-family: Kanit;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #6c6b6b;
  margin: 0;
  padding: 0;
}

.txt-show-error-required {
  /* font-family: Kanit; */
  font-size: 1rem;
  margin-left: 10px;
  margin-top: 5px;
  color: #ff2d2d;
}

.txt-confirm-create-new-employee {
  font-family: Kanit;
  font-weight: normal;
  font-size: 16px;
  color: #6c6b6b;
}

.float-right {
  float: right !important;
}

@media only screen and (max-width: 1199px) {
  .txt-top-title-school-by-nile {
    text-align: center;
  }
  .txt-under-title {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .txt-header-Dashboard {
    display: none;
  }
  .txt-header-time-Dashboard {
    text-align: center;
  }
}

@media only screen and (min-width: 767px) {
  .txt-header-time-Dashboard {
    text-align: right;
  }
}
