.div-display-image-table-activities {
  width: 100%;
  display: flex;
  position: relative;
}

.div-image-train {
  margin-right: 50px;
  position: relative;
}

.div-image-log {
  position: relative;
}

.image-components-display {
  width: 120px;
  height: 130px;
  border-radius: 13px;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.text-in-image-components-display {
  position: absolute;
  top: 0;
  border-radius: 25px;
  width: 100%;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.404);
}

.modalStyle-ModalSelectATK .ant-modal-content {
  border-radius: 25px;
}