.col-part-sm-by-nile{
    display: none; 
}

.col-part-lg-by-nile {
    margin-right: 5px;
}

@media only screen and (max-width: 767px) {
    .col-part-lg-by-nile{
        display: none;
    }

    .col-part-sm-by-nile{
        display: inline;
        margin-right: 5px;
    }
}
