.btn-turnoff-and-reboot {
    width: 100%;
    height: 50px;
    border-radius: 17px;
    font-family: Kanit;
    font-weight: normal;
    font-size: 1.2rem;
    text-align: center;
    cursor: pointer;
    background: #FFF;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
}

.margin-box-device-information {
    margin: 0px 30px 30px 30px;
}

.btn-turnoff-and-reboot:hover {
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.btn-turnoff-device{
    border: 2px solid #ea6969;
}

.btn-reboot-device{
    border: 2px solid #FCB64A;
}

.btn-submit-config {
    width: 200px;
    height: 40px;
    border-radius: 17px;
    font-family: Kanit;
    font-weight: normal;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    background: #134e96;
    color: #FFF;
    border: none;
    margin: 20px auto 20px auto;
    display: block;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
}

@media only screen and (max-width: 767px) {
    .btn-turnoff-device{
        margin-bottom: 10px;
    }

    .btn-reboot-device{
        margin-bottom: 10px;
    }

    .margin-box-device-information {
        margin: 10px;
    }

    .btn-submit-config {
        width:100%;
    }
  }
  
  /* @media only screen and (min-width: 767px) {
    .btn-turnoff-device{

    }

    .btn-reboot-device{

    }
  } */