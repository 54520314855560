.box-top-trainimage {
  width: 100%;
  /* height: 227px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
}
.box-top-trainimage img {
  width: 135px;
  object-fit: contain;
  border-radius: 14px;
}
.img-icon-train {
  width: 1.2rem !important;
  object-fit: contain;
  border-radius: 0 !important;
}

.box-top-trainimage-by-nile {
  /* width: 100%; */
  /* height: 227px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  /* display: flex; */
  /* flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  padding: 1rem 1rem;
}

.image-user-log-in-card-by-nile {
  width: 135px;
  object-fit: contain;
  border-radius: 14px;
  display: block;
  margin: auto;
}

.status-img-train-in-card-by-nile {
  width: 40px;
  object-fit: contain;
  border-radius: 0px;
}

.txt-sub-under-image-by-nile {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .div-DisplayTextHeaderAndValue-by-nile{
margin-top: 0px;
  }

  .button-save-TrainImageFromActivities {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 0px;
  }
}

@media only screen and (min-width: 767px) {
  .div-DisplayTextHeaderAndValue-by-nile{
    margin-top: 20px;
  }

  .button-save-TrainImageFromActivities {
    width: 350px;
    margin-top: 10px;
    padding: 20px;
  }

}