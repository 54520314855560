.box-information {
  width: 855px;
  /* height: 793px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  margin-top: 2rem;
  padding: 0 2rem;
  padding-bottom: 2rem;
}
.box-information-by-nile {
  /* width: 855px; */
  /* height: 793px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  /* margin-top: 2rem; */
  padding: 0 2rem;
  padding-bottom: 2rem;
}
.box-details {
  width: 50%;
  /* height: 358px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  padding: 0 2rem;
  padding-bottom: 1rem;
}
.box-details-by-nile {
  /* width: 50%; */
  /* height: 358px; */
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  padding: 0 2rem;
  padding-bottom: 1rem;
}
.w70x {
  width: 70%;
}
.w50 {
  width: 50%;
}
.w30 {
  width: 30%;
}
.w15 {
  width: 15%;
}
.img55per {
  width: 55%;
}
.img25per {
  width: 25%;
}
.w50-my2 {
  width: 50%;
  margin: 0.5rem 0;
  height: 50%;
  object-fit: contain;
}

.img-device-information-by-nile{
  height: 80%;
}

.div-img-device-information-by-nile{
  height: 255px;
  padding: 20px;
}