.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  /* position: relative !important;
    background-color: #fff !important;
    border: 1px solid red !important;
    border-radius: 2px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important; */

  text-align: left !important;
  font-family: Kanit !important;
  font-weight: normal !important;
  height: 50px !important;
  /* width: 100% !important; */
  border-radius: 19px !important;
  margin: 0px !important;
  padding: 15px !important;
  background: #ebf4ff !important;
  border: none !important;
  /* box-shadow: 0px 0px 6px rgba(19, 78, 150, 0.09); */

}

.ant-select-selector:hover .ant-select-selector:active {
  border: 1px solid red !important;
  box-shadow: none !important;
}

.ant-select-arrow span {
  font-size: 20px !important;
  margin-left: -20px !important;
}

.ant-select-clear {
  background: #ebf4ff !important;
}

.ant-select-clear span {
  font-size: 20px !important;
  margin-left: -20px !important;
  background: #ebf4ff !important;
}

.ant-pagination-item-active {
  border-radius: 11px !important;
  background: #134e96 !important;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09) !important;
  border: none !important;
  font-family: Kanit !important;
  font-weight: normal !important;
  font-size: 1rem !important;
  text-align: center !important;
  color: #FFF !important;
  cursor: pointer !important;
  margin: 5px !important;
}

.ant-pagination-item {
  border-radius: 11px !important;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09) !important;
  border: none !important;
  font-family: Kanit !important;
  font-weight: normal !important;
  font-size: 1rem !important;
  text-align: center !important;
  color: #7b7b7b;
  cursor: pointer !important;
  margin: 5px !important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    font-size: 12px !important;
    text-align: center !important;
    background-color: #fff !important;
    border: none  !important;
    border-radius: 11px  !important;
    outline: none !important;
    transition: all 0.3s !important;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09) !important;
}

.ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  margin-right: 0px !important;
}