.box-add-new-student {
  width: 100%;
  border-radius: 35px;
  background: #fff;
  border: 3px solid #134e96;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  margin-top: 2rem;
  padding: 1rem 2rem;
}
.box-add-new-student-by-nile {
  width: 100%;
  border-radius: 35px;
  background: #fff;
  border: 3px solid #134e96;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  margin-top: 2rem;
  padding: 1rem;
}
.box-main-name-add-new-student {
  /* width: 708px;
  height: 298px; */
  border-radius: 31px;
  background: transparent;
  border: 1px solid #d8d8d8;
  border-style: dashed;
  padding: 0.7rem 1.4rem;
}
.padding-div-border-input-field-add-new-student {
  padding: 5px;
}
.div-upload-image-add-new-student {
  margin-top: 10px;
}
.box-image-train-new-student {
  /* width: 150px; */
  height: 100%;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 0px 9px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  /* padding-bottom: 1rem; */
  cursor: pointer;
}
.box-circle-region-img {
  width: 87px;
  height: 87px;
  background: #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 15px rgba(19, 78, 150, 0.09));
}
.box-circle-region-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
