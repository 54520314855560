/* From Bizzly Device */
.box-relative-setting {
  position: relative;
  height: 100%;
}
.box-setting {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  position: absolute;
  z-index: 1400;
  right: 2vw;
  top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  padding: 2rem 2rem;
}
.box-setting img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-top: 1rem;
}
#show-setting {
  opacity: 1;
  height: auto;
}
#hide-setting {
  opacity: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
}

/* From Setting Page */
.box-img-avatar {
  width: 140px;
  height: 140px;
  background: #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 14px rgba(19, 78, 150, 0.09));
}
.box-img-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.col-setting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-setting-dashline {
  /* width: 65%; */
  /* height: 422px; */
  border-radius: 52px;
  background: #fff;
  border: 2px solid #c7c7c7;
  border-style: dashed;
}
.box-color-region {
  width: 32px;
  height: 32px;
  background: #e58ca4;
  border-radius: 50%;
  cursor: pointer;
}
.box-button-back-to-bizzly {
  width: 100%;
  height: 95px;
  border-radius: 35px;
  background: #fff;
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #707070;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
}
.box-button-back-to-bizzly img {
  width: auto;
  height: 55%;
  object-fit: contain;
}
.box-button-back-to-bizzly:hover {
  filter: brightness(98%);
}
