.slider-box {
  display: flex;
  flex-direction: row;
  width: 61%;
  position: relative;
  border-radius: 21px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
}
.slider-box-by-nile {
  display: flex;
  flex-direction: row;
  width: 61%;
  position: relative;
  border-radius: 21px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  margin: auto;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .slider-box-by-nile {
    width: 100%;
  }
}
.button-slider {
  width: 50%;
  height: 51px;
  border-radius: 21px;
  background: transparent;
  /* box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09); */
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #7b7b7b;
  cursor: pointer;
  z-index: 1201;
  transition: color 0.5s ease-in-out;
}
.box-slider-cover {
  width: 50%;
  height: 51px;
  border-radius: 21px;
  background: #7aa6dd;
  position: absolute;
  left: 0;
  z-index: 1200;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  transition: left 0.5s ease-in-out;
}
