.one-check-box-checked {
  width: 15px;
  height: 15px;
  background: #fff;
  border: 1px solid #707070;
  filter: drop-shadow(0px 0px 14px rgba(19, 78, 150, 0.13));
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}
.box-one-check-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;
}
#one-check-box-has-checked {
  background: #134e96;
  border: 0px solid #707070;
}

.box-one-check-box-by-nile {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  /* cursor: pointer; */
}

.one-check-box-checked-by-nile {
  width: 15px;
  height: 15px;
  background: #fff;
  border: 1px solid #707070;
  filter: drop-shadow(0px 0px 14px rgba(19, 78, 150, 0.13));
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
  cursor: pointer;
}

/* .one-check-box-checked {
  width: 15px;
  height: 15px;
  background: #fff;
  border: 1px solid #707070;
  filter: drop-shadow(0px 0px 14px rgba(19, 78, 150, 0.13));
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}
.box-one-check-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;
}
#one-check-box-has-checked {
  background: #134e96;
  border: 0px solid #707070;
} */
