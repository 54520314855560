@media only screen and (max-width: 400px) {
    .text-in-button-employee {
        display: none;
    }

    .icons-in-button-new-employee {
        display: inline;
    }
  
  }
  
  @media only screen and (min-width: 401px) {
    .text-in-button-employee {
        display: inline;
    }

    .icons-in-button-new-employee {
        display: none;
    }

  }