.box-slide-on-off {
  width: 65px;
  height: 35px;
  border-radius: 25.5px;
  background: #f0f0f0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  align-items: center;
}
.toggle-circle {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #69ea87;
  position: relative;
  left: 9px;
  top: 0;
  transition: left 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.txt-toggle {
  font-family: Kanit;
  font-weight: normal;
  font-size: 0.7rem;
  text-align: left;
  color: #7b7b7b;
  position: absolute;
  top: 9px;
  /* left: 19px; */
  padding: 0;
  margin: 0;
  transition: opacity 0.3s ease-in-out;
}
#toggle-left {
  left: 9px;
  top: 0;
  background-color: #69ea87;
}
#toggle-right {
  left: 38px;
  top: 0;
  background-color: #ea6969;
}
#show-txt-toggle {
  opacity: 1;
}
#hide-txt-toggle {
  opacity: 0;
}
/* For MINI Version */
.box-slide-on-off-mn {
  width: 55px;
  height: 22px;
  border-radius: 25.5px;
  background: #f0f0f0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  align-items: center;
}
.box-slide-on-off-mn-by-nile {
  width: 55px;
  height: 22px;
  border-radius: 25.5px;
  background: #f0f0f0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  align-items: center;
  margin:auto;
}
.toggle-circle-mn {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #69ea87;
  position: relative;
  left: 9px;
  top: 0;
  transition: left 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.txt-toggle-mn {
  font-family: Kanit;
  font-weight: normal;
  font-size: 0.7rem;
  text-align: left;
  color: #7b7b7b;
  position: absolute;
  top: 2px;
  /* left: 19px; */
  padding: 0;
  margin: 0;
  transition: opacity 0.3s ease-in-out;
}
#toggle-left-mn {
  left: 6px;
  top: 0;
  background-color: #69ea87;
}
#toggle-right-mn {
  left: 36px;
  top: 0;
  background-color: #ea6969;
}
#show-txt-toggle-mn {
  opacity: 1;
}
#hide-txt-toggle-mn {
  opacity: 0;
}
