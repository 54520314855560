.header-train-image-from-activities-public-page {
  background-color: #7aa7dd8f;
}

.img-header-train-image-from-activities-public-page {
  display: block;
  margin: auto;
}

.text-header-train-image-from-activities-public-page {
  text-align: center;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  color: #707070;
  /* margin-top: 20px;
  margin-bottom: 20px; */
}

.text-input-user-ihr {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  color: #707070;
  margin-top: 20px;
  margin-bottom: 0px;
}

.add-font-family {
  font-family: Kanit;
}

.img-train-image-from-activities-public-page-sm {
  border-radius: 13px;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  display: block;
  margin: auto;
}

.img-train-image-from-activities-public-page-lg {
  border-radius: 13px;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}

.div-line-color {
  height: 30px;
  background-color: #ebf4ff;
}

.center-animation-success {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.text-add-feature-center-animation-success {
  color: green;
  text-align: center;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
}

@media only screen and (max-width: 767px) {
  .display-card-sm {
    display: block;
  }
  .display-card-lg {
    display: none;
  }
  .div-content-consent-modal {
    height: 150px;
    }
}

@media only screen and (min-width: 767px) {
  .display-card-sm {
    display: none;
  }
  .display-card-lg {
    display: block;
  }
  .div-content-consent-modal {
    height: 300px;
    }
}
