.device-details-box {
  /* width: 234px;
  height: 222px; */
  border-radius: 35px;
  object-fit: contain;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  /* display: inline-flex; */
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  margin: 1.5rem 1.5rem;
}
.device-details-box-by-nile {
  /* width: 234px;
  height: 222px; */
  border-radius: 35px;
  object-fit: contain;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  /* display: inline-flex; */
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  margin: 1.5rem 1.5rem;
  cursor: pointer;
}
.device-details-box:hover {
  box-shadow: 0px 0px 35px rgba(19, 78, 150, 0.18);
}
.img-device-details-box {
  width: 100%;
}
.device-details-box p {
  font-size: 20px;
}
.device-details-box:hover p {
  color: #134e96;
}
