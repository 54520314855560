.box-graph-dashbaord {
  margin-top: 2rem;
  width: 100%;
  height: 375px;
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
}
.box-top-show {
  /* width: 294px; */
  /* height: 150px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 1rem;
  /* align-items: center; */
  border-radius: 35px;
  background: #fff;
  padding: 0.2rem 0.2rem;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
}
.box-top-show-by-nile {
  border-radius: 35px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  margin-top: 20px;
}
.img-top-show {
  width: 3vw;
  height: 3vw;
  object-fit: contain;
  margin-top: 1rem;
}
.img-top-show-by-nile {
  height: 69px;
  width: 50px;
  display: block;
  margin: auto;
  object-fit: contain;
  /* margin-top: 1rem; */
}
.coltop-show {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.box-graph-total {
  /* width: 1616px; */
  height: auto;
  border-radius: 35px;
  background: #fff;
  margin-top: 0 2rem;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
}
.mt-2 {
  height: 1.5rem;
}
.box-bar-chart {
  width: 100%;
  height: 100px;
  background-color: blueviolet;
}
.box-color-bar-graph {
  width: 23px;
  height: 5px;
  border-radius: 2.5px;
  background: #fe65da;
}
.txt-color-bar-graph {
  font-family: Kanit;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: #a7a7a7;
  margin: 0;
  padding: 0;
}
.row-color-bar-graph {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.2rem;
}
.box-summary-below-graph {
  /* width: 999px; */
  /* height: 194px; */
  border-radius: 31px;
  background: transparent;
  border: 1px solid #d8d8d8;
  border-style: dashed;
  margin: 1rem 0;
  padding: 0.5rem 0.5rem;
}
.col-icon-text-summary-below-graph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.txt-icon-text-summary-below-graph {
  font-family: Kanit;
  font-weight: normal;
  font-size: 24px;
  text-align: left;
  color: #7b7b7b;
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  /* line-height: 2;
  -webkit-line-clamp: 2; */
}
.txt-explanation-summary-below-graph {
  font-family: Kanit;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #c7c7c7;
}
.img-summary-below-graph {
  width: 48px;
  height: 48px;
  object-fit: contain;
}
.svg-appear {
  opacity: 1;
  animation-name: appear;
  width: 74%;
  /* height: 92vh; */
  /* aspect-ratio: none; */
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
  animation-duration: 1s;
  /* background-color: aqua; */
  position: relative;
  object-fit: contain;
  /* animation-iteration-count: ; */
}
.text-header-total-dashboard-lg-graph-by-nile {
  font-family: Kanit;
  font-weight: 400;
  font-size: 1.3rem;
  text-align: center;
  font-size: 2rem;
  color: #5a5959;
  margin: 10px;
}
.text-header-total-dashboard-sm-graph-by-nile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .text-header-total-dashboard-lg-graph-by-nile {
    display: none;
  }
  .text-header-total-dashboard-sm-graph-by-nile {
    font-family: Kanit;
    font-weight: 400;
    font-size: 1.3rem;
    text-align: center;
    font-size: 2rem;
    color: #5a5959;
    /* margin: 10px; */
    display: block;
  }
}

@keyframes appear {
  from {
    opacity: 1;
    width: 74%;
  }

  to {
    opacity: 0;
    width: 0%;
    /* height: 0; */
  }
}
.path-map-hover:hover {
  filter: brightness(90%);
}
.pointer-icon {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  animation-name: appear-factory;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-duration: 1s;
  /* animation-delay: 1s; */
}
@keyframes appear-factory {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    /* height: 0; */
  }
}
.pos-center-div {
  display: flex;
  /* width: 100%; */
  flex-direction: row;
  justify-content: center;
  /* background-color: blueviolet; */
  position: relative;
}
.relative-div {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.relative-pointer {
  position: absolute;
  width: 18;
  height: 18;
}
.box-pointer-hover {
  /* width: 100px;
  height: 100px; */
  width: 240px;
  border-radius: 35px;
  padding: 0.5rem 0.5rem;
  position: absolute;
  background: #fff;
  visibility: hidden;
  z-index: 3500;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
}
.button-pointer-hover-close {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ffbebe;
  position: absolute;
  border: none;
  /* margin: 0; */
  /* margin-bottom:2rem; */
  color: white;
  right: -5px;
  top: -5px;
  /* position: absolute;
  z-index: 100; */
}
.txt-pointer-hover-title {
  font-family: Kanit;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #585858;
  padding: 0;
  margin: 0;
  font-weight: 550;
}
.txt-pointer-hover-content {
  font-family: Kanit;
  font-weight: normal;
  font-size: 10px;
  text-align: left;
  color: #adadad;
  padding: 0;
  margin: 0;
}
.txt-pointer-hover-utherm {
  font-family: Kanit;
  font-weight: normal;
  font-size: 11px;
  text-align: left;
  color: #6c6b6b;
  padding: 0;
  margin: 0;
}
.img-utherm-pointer-hover {
  height: 26px;
}
.pointer-hover-col {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.mt-1 {
  margin-top: 2rem;
  height: 1rem;
}
.box-pointer-duplicated-factory {
  width: 16px;
  height: 16px;
  background: #919191;
  border-radius: 50%;
  position: absolute;
  font-family: Kanit;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #fff;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 200;
}
.txt-number-duplicated-factory {
  font-family: Kanit;
  font-weight: normal;
  font-size: 5px;
  text-align: left;
  color: #fff;
  padding: 0;
  margin: 0;
  /* position: absolute; */
}
/* #THA375 {
  position: relative;
} */
