.button-new-student-by-nile {
    width: 100%;
    height: 50px;
    border-radius: 17px;
    background: #fff;
    box-shadow: rgb(19 78 150 / 9%) 0px 2px 8px 0px;
    border: none;
    font-family: Kanit;
    font-weight: normal;
    font-size: 1.2rem;
    text-align: center;
    color: #7b7b7b;
    cursor: pointer;
}

.button-new-student-by-nile:hover {
    box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.247);
}

.button-new-student-by-nile:active {
    box-shadow: 3px 3px 14px rgba(14, 109, 224, 0.473);
  }

  @media only screen and (max-width: 767px) {
    .button-new-student-by-nile{
        margin-bottom: 10px;
    }
  }
  
  @media only screen and (min-width: 767px) {
    .button-new-student-by-nile{
        margin-top: 45px;
    }
  }