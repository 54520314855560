.rec.rec-dot {
    background: #e2e2e2;
    border: none;
    box-shadow:none;
    width:15px;
    height: 15px;
  }

  .rec.rec-dot_active {
    background: #134e96;
    border: none;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  }

  button.rec-dot:hover {
    background: #7aa4dd;
    border: none;
    box-shadow: none;
  }

 button.rec-dot:active, button.rec-dot:focus  {
    background: #134e96;
    border: none;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  }