.box-cover-dialog-background {
  width: 100vw;
  height: 100vh;
  background: rgba(123, 123, 123, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.box-position-dialog {
  position: relative;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 3000;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.box-dialog {
  width: 90%;
  /* height: 65vh; */
  border-radius: 65px;
  background: #fff;
  position: fixed;
  top: 10%;
}
#show-dialog {
  visibility: visible;
}
.box-table-inside-dialog {
  width: auto;
  height: 200px;
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  margin: 1rem 2rem;
  /* z-index: 4000; */
}
.box-table-inside-dialog-by-nile {
  width: auto;
  height: 250px;
  /* border-radius: 35px; */
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  margin: 1rem 2rem;
  /* z-index: 4000; */
}
@media only screen and (max-width: 600px) {
  .box-dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .box-dialog {
    width: 70%;
  }
}

@media only screen and (min-width: 993px) {
  .box-dialog {
    width: 50%;
  }
}
