@media only screen and (max-width: 767px) {
    .loading-screen-video{
    text-align: center;
   padding-top: 150px;
    }
  }
  
  @media only screen and (min-width: 767px) {
    .loading-screen-video{
        text-align: center;
        padding-top: 150px;
        padding-left: 50px;
    }
  }