.box-already-in-system-field {
  width: 100%;
  border-radius: 35px;
  background: #fff;
  border: 3px solid #134e96;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  margin-top: 2rem;
  padding: 1rem 2rem;
}

.box-already-in-system-field-by-nile {
  width: 100%;
  border-radius: 35px;
  background: #fff;
  border: 3px solid #134e96;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
  margin-top: 2rem;
  padding: 1rem;
}

.box-insystem-field {
  width: 100%;
  border-radius: 31px;
  background: transparent;
  border: 1px solid #d8d8d8;
  border-style: dashed;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* column-gap: 2rem; */
}
.box-insystem-field-by-nile {
  width: 100%;
  border-radius: 31px;
  background: transparent;
  border: 1px solid #d8d8d8;
  border-style: dashed;
  padding: 1rem;
  /* display: flex; */
  /* flex-direction: row;
  justify-content: space-between; */
  /* column-gap: 2rem; */
}
.box-image-train {
  width: 150px;
  height: 150px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 0px 9px rgba(19, 78, 150, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-bottom: 1rem;
  cursor: pointer;
}
