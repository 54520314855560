@media only screen and (max-width: 767px) {
  .display-environment-lg-by-nile {
    display: none;
  }
  .txt-header-display-environment {
    text-align: left;
  }
  .txt-sub-display-environment {
    text-align: left;
    margin-bottom: 30px;
  }

}

@media only screen and (min-width: 768px) {
  .display-environment-sm-by-nile {
    display: none;
  }
  .txt-header-display-environment {
    text-align: center;
  }
  .txt-sub-display-environment {
    text-align: center;
  }
}

.img-display-environment {
    width: 48px;
    height: 48px;
  display: block;
  margin: 10px auto 10px auto;
}

.txt-header-display-environment {
  font-family: Kanit;
  font-weight: 400;
  font-size: 1.2rem;
  /* text-align: center; */
  color: #707070;
  margin: 0 0;
}

.txt-sub-display-environment {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  /* text-align: left; */
  color: #cecbcb;
  margin-top: 10px;
}

.txt-sub-display-box-cpu-by-nile {
  font-family: Kanit;
  font-weight: normal;
  font-size: 17px;
  text-align: left;
  color: #7b7b7b;
}