/* start scroll bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #7aa4dd; */
  background-color: #e6e6e6;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #7aa6dd;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7aa6dd;
}
/* end scroll bar */
