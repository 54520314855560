@media only screen and (max-width: 1420px) {
  .header-bar-lg {
    display: none;
  }
}

@media only screen and (min-width: 1421px) {
  .left-bar-menu-sm {
    display: none;
  }
}

@media only screen and (max-width: 1047px) {
  .header-bar-lg-user {
    display: none;
  }
}

@media only screen and (min-width: 1048px) {
  .left-bar-menu-sm-user {
    display: none;
  }
}

.nav-box {
  width: 100%;
  height: 55px;
  border-radius: 0px 0px 35px 35px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  /* left: 0;
  height: 0;
  z-index: 1000; */
}
.nav-title-logo {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: left;
  margin: 0.5rem 1rem;
  /* padding: 0%; */
  color: #134e96;
}

.nav-title {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: 2rem;
  margin-top: 0.5rem;
}
.nav-title img {
  height: 65%;
}
.h55px {
  height: 59px;
}
