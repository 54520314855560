* {
  box-sizing: border-box;
}

@import "_navigator.css";
@import "_typography.css";
@import "_button.css";
@import "_align.css";
@import "_box_dashboard.css";
@import "_dropdown.css";
@import "_box_all_device.css";
@import "_box_video.css";
@import "_scrollbar.css";
@import "_videoPage.css";
@import "_tableLog.css";
@import "_box_new_device.css";
@import "_box_qr_code.css";
@import "_slider_tab.css";
@import "_texfield.css";
@import "_box_fill.css";
@import "_box_device_information.css";
@import "_add_device.css";
@import "_box_setting.css";
@import "_box_slide_on_off.css";
@import "_circle_indicator.css";
@import "_react_elastic_carousel.css";
@import "_DisplayTextValueResponsive.css";
@import "_display_environment.css";
@import "_DisplayImageTableActivities.css";
@import "_buttonTrainImage_when_no_id.css";
@import "_select_date_time.css";
@import "_testSelect.css";
@import "_inputSelect.css";
@import "_box_top_tranimage.css";
@import "_box_seleted_field.css";
@import "_box_already_In_system_field.css";
@import "_box_add_new_student.css";
@import "_students.css";
@import "_tempurature_page.css";
@import "_dropdown_filter.css";
@import "_one_check_box.css";
@import "_attendance_page.css";
@import "_history_illness_page.css";
@import "_growth_page.css";
@import "_dialog.css";
@import "_personal_student.css";
@import "_table_dashboard.css";
@import "_table_temperature.css";
@import "_table_attendance.css";
@import "_table_growth.css";
@import "_table_history_illness.css";
@import "_image_hover_display_upper.css";
@import "_box_slider_switch.css";
@import "_icons_style_app_factory.css";
@import "_train_image_from_activities_public.css";
@import "_switch_toggle_components.css";
@import "_icons_in_components_style.css";
@import "_login_page.css";
@import "_device_one.css";
@import "_spin_loading.css";
@import "_announcement.css";
@import "_modalSelectEmployee.css";