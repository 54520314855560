/* display pc */
.nav-button {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  background-color: white;
  text-align: left;
  color: #7b7b7b;
  border: none;
  height: 100%;
  margin: 0 2rem;
  cursor: pointer;
  transition: border 0.1s;
}

.active-navbar {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  background-color: white;
  text-align: left;
  border: none;
  height: 100%;
  margin: 0 2rem;
  cursor: pointer;
  transition: border 0.1s;

  color: #134e96;
  border-bottom: 2px solid #134e96;
}

.nav-button:active {
  color: #134e96;
  border-bottom: 2px solid #134e96;
}
.nav-button:hover {
  color: #134e96;
}
.nav-button:focus {
  color: #134e96;
  border-bottom: 2px solid #134e96;
}
.button-pagination {
  width: 42px;
  height: 42px;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #7b7b7b;
  cursor: pointer;
  margin: 0 0.5rem;
}
.button-pagination-active {
  width: 42px;
  height: 42px;
  border-radius: 11px;
  background: #134e96;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #FFF;
  cursor: pointer;
  margin: 0 0.5rem;
}
.button-pagination-by-nile {
  /* width: 32px;
  height: 32px; */
  padding: 10px;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #7b7b7b;
  cursor: pointer;
  margin: 5px;
}
.button-pagination-active-by-nile {
  /* width: 32px;
  height: 32px; */
  padding: 10px;
  border-radius: 11px;
  background: #134e96;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #FFF;
  cursor: pointer;
  margin: 5px;
}
.disabled-step-button-pagination {
  cursor: not-allowed !important;
  color: #E5E5E5;
}
.icon-txt-button {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #134e96;
  /* padding: 0.25rem 3rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  /* margin: 0 1rem; */
  cursor: pointer;

  /* ไนล์เพิ่ม */
  /* padding: 0.55rem 3rem; */
  width: 100%;
  height: 40px;
  margin-top: 20px;
}

.icon-txt-button:hover {
  filter: brightness(120%);
  box-shadow: 0px 0px 35px rgba(19, 78, 150, 0.18);
}

/* display mobile */

.div-menu-mobile-list {
  margin-bottom: 20px;
}

.nav-button-mb {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  background-color: rgb(248, 249, 254);
  text-align: left;
  color: #7b7b7b;
  border: none;
  /* height: 100%; */
  margin: 0 2rem;
  cursor: pointer;
  transition: border 0.1s;
}

.active-navbar-mb {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  background-color: rgb(248, 249, 254);
  text-align: left;
  border: none;
  /* height: 100%; */
  margin: 0 2rem;
  cursor: pointer;
  transition: border 0.1s;

  color: #134e96;
  border-bottom: 2px solid #134e96;
}

.nav-button-mb:active {
  color: #134e96;
  border-bottom: 2px solid #134e96;
}
.nav-button-mb:hover {
  color: #134e96;
}
.nav-button-mb:focus {
  color: #134e96;
  border-bottom: 2px solid #134e96;
}
.button-pagination-mb {
  width: 42px;
  height: 42px;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #7b7b7b;
  cursor: pointer;
  margin: 0 0.5rem;
}
.icon-txt-button-mb {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #134e96;
  padding: 0.25rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  margin: 0 1rem;
  cursor: pointer;
}
.icon-button {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  color: #134e96;
  margin: 0 1rem;
  padding: 0.5rem 2rem;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  font-family: Kanit;
}
.icon-button-by-nile {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  color: #134e96;
  /* margin: 0 1rem; */
  padding: 0.5rem 2rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}
.icon-button-by-nile:hover {
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
}
.icon-txt-button2-by-nile:hover {
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
}
.icon-txt-button2-by-nile {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  color: #134e96;
  /* margin: 0 1rem; */
  padding: 0.5rem 2rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  column-gap: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  width: 100%;
}
.icon-txt-button2 {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  color: #134e96;
  margin: 0 1rem;
  padding: 0.5rem 2rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  column-gap: 0.5rem;
  margin: 0 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.primary-button {
  border-radius: 12px;
  background: #134e96;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0.7rem 2rem;

  /* transition: outline 0.3s ease-in-out; */
}
.primary-button:hover {
  filter: brightness(129%);
}
.primary-button:active:hover {
  filter: brightness(89%);
}
/* .primary-button:active {
  outline: 5px solid rgba(19, 78, 150, 0.24);
  box-shadow: 0px 0px 35px rgba(19, 78, 150, 0.59);
} */
@keyframes onMouseUp {
  0% {
    outline: 0px solid rgba(19, 78, 150, 0);
  }
  25% {
    outline: 5px solid rgba(19, 78, 150, 0.24);
  }
  50% {
    outline: 5px solid rgba(19, 78, 150, 0.18);
  }
  100% {
    outline: 5px solid rgba(19, 78, 150, 0);
  }
}
#unclick {
  outline: none;
}
#click {
  animation: onMouseUp 0.5s alternate ease-in-out;
}
.floating-button {
  width: 65px;
  height: 65px;
  color: white;
  background: #134e96;
  border: none;
  border-radius: 50%;
  position: fixed;
  right: 6%;
  bottom: 6%;
  font-size: 1.5rem;
  text-align: center;
  padding: 0 0;
  margin: 0;
  filter: drop-shadow(3px 3px 9px rgba(19, 78, 150, 0.16));
}
.floating-button:hover {
  filter: brightness(129%);
}
.floating-button:active:hover {
  filter: brightness(89%);
}
/* .floating-button:active {
  animation: onMouseUp 1s reverse ease-in-out;
  animation-iteration-count: 1;
} */
.floating-button:target {
  animation: onMouseUp 1s reverse ease-in-out;
  animation-iteration-count: 1;
}
/* .floating-button:focus {
} */
.button-setting {
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  background-color: white;
  height: 100%;
  text-align: left;
  color: #7b7b7b;
  border: none;
  height: 100%;
  margin: 0 2rem;
  cursor: pointer;
  transition: border 0.1s;
}
.button-setting:active {
  color: #134e96;
}

.primary-button-by-nile {
  border-radius: 19px;
  background: #134e96;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0.7rem 2rem;
  width: 250px;
  display: block;
  margin: auto;
}
.primary-button-by-nile:hover {
  filter: brightness(129%);
}
.primary-button-by-nile:active:hover {
  filter: brightness(89%);
}
.icon-txt-button2 {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #134e96;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 2rem;
}

.icon-txt-button2:hover {
  filter: brightness(120%);
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.18);
}
.text-button {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  border: none;
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #134e96;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 5rem;
}

.text-button:hover {
  filter: brightness(120%);
  box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.18);
}
.primary-button2 {
  border-radius: 12px;
  background: #134e96;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0.5rem 5rem;

  /* transition: outline 0.3s ease-in-out; */
}
.primary-button2-by-nile {
  width: 100%;
  border-radius: 12px;
  background: #134e96;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0.5rem 5rem;

  /* transition: outline 0.3s ease-in-out; */
}
.button2-save-create-new-employee-by-nile {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  background: #134e96;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  font-family: Kanit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;

  /* transition: outline 0.3s ease-in-out; */
}
.icon-button:hover {
  filter: brightness(96%);
}
.primary-button2:hover {
  filter: brightness(120%);
}
.primary-button:hover {
  filter: brightness(129%);
}
.primary-button:active:hover {
  filter: brightness(89%);
}

.button-cancel-form {
  float: right;
}

.button-fixed-bottom-menu-mobile{
  position: fixed;
  bottom: 0;
  margin-left: 25px;
  /* padding: 25px; */
}

.text-button-back-fixed-bottom-menu-mobile{
  font-family: Kanit;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;
  color: #7b7b7b;
}

.btn-rebuild-all-rpc-in-system-setting {
  border-radius: 12px;
  background: #fff;
  box-shadow: 3px 3px 14px rgba(19, 78, 150, 0.09);
  color: #134e96;
  margin: 0 1rem;
  padding: 0.5rem 2rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  column-gap: 0.5rem;
  margin: 0 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

/* .button-ok-form {

} */

@media only screen and (max-width: 767px) {
  .div-border-modal-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .button-cancel-form {
    width: 100%;
  }

  .button-ok-form {
    margin-top: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .btn-rebuild-all-rpc-in-system-setting {
    width: 80%;
    display: block;
    margin: auto !important;
  }
}
