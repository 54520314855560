.pt2 {
    padding-top: 2rem;
  }
  .grid70-30 {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 3fr 1fr;
    column-gap: 2rem;
    /* background-color: chartreuse; */
    align-items: flex-start;
  }
  .box-on-top-attendance-page {
    width: auto;
    height: auto;
    border-radius: 35px;
    background: #fff;
    box-shadow: 0px 0px 14px rgba(19, 78, 150, 0.09);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    justify-content: space-between;
    padding: 1rem 1rem;
    margin-top: 1rem;
  }
  .box-dash-attendance-page {
    /* width: 509px; */
    /* height: 154px; */
    border-radius: 23px;
    background: transparent;
    border: 2px solid #c7c7c7;
    border-style: dashed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  .box-table-attendance {
    width: auto;
    height: 538px;
    border-radius: 35px;
    background: #fff;
    box-shadow: 0px 0px 24px rgba(19, 78, 150, 0.09);
    margin-top: 2rem;
  }
  