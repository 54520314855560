.triangle-DisplayImageHoverDisplayUpper-right{
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #FFF;
    -ms-transform: rotate(36deg); 
    transform: rotate(36deg);
    position: absolute;
    top: 94px;
    filter: drop-shadow(0px 10px 15px -3px rgba(0,0,0,0.1));
  }

  .triangle-DisplayImageHoverDisplayUpper-left {
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #FFF;
    -ms-transform: rotate(329deg); 
    transform: rotate(329deg);
    position: absolute;
    filter: drop-shadow(0px 10px 15px -3px rgba(0,0,0,0.1));
    top: 97px;
    left: 67px;
  }